* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    height: 100vh;
    width: 100vw;
    
    background: rgb(9,18,51);
    background: rgb(9,18,51);
    background: -moz-linear-gradient(180deg, rgba(9,18,51,1) 0%, rgba(71,49,152,1) 50%, rgba(58,134,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(9,18,51,1) 0%, rgba(71,49,152,1) 50%, rgba(58,134,255,1) 100%);
    background: linear-gradient(180deg, rgba(9,18,51,1) 0%, rgba(71,49,152,1) 50%, rgba(58,134,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#091233",endColorstr="#3a86ff",GradientType=1);

    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  header {
    margin-top: 3rem;
    text-align: center;
  }
  
  h1 {
    font-size: 1.4rem;
    color:white;
    /* margin-bottom: 1rem; */
  }

  .logo {
    width: 200px;
    height: auto;
  }
  
  main {
    text-align: center;
    margin-bottom: 20rem;
  }
  
  
  
  .headline-notify {
    margin-top: 1em;
    font-size: 0.8rem;
    font-family: Helvetica;
    font-weight: 100;
    color:white;
  }

  form {
    margin-top: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  label {
    font-size: 1.5rem;
    margin-right: 1rem;
  }
  
  input[type="email"] {
    font-size: 1rem;
    padding: 0.7em 1rem 0.7rem 1rem;
    border-radius: 0.3rem;
    border: none;
    margin-right: 0.3rem;
  }
  
  #submit-btn {
    font-size: 1rem;
    padding: 0.7rem 2rem;
    border-radius: 0.3rem;
    border: none;
    background-color: #3a86ff;
    color: white;
    cursor: pointer;
  }
  
  .submit-result {
    margin-top: 1em;
    color:white;
    visibility: hidden;
  }

  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    padding: 0.5rem;
    font-size: 0.8rem;
  }
  
  @media (min-width:1281px) { 

    header {
      margin-left: 3rem;
      text-align: left;
    }  

    h1
    {
      font-size: 3rem;
    }

    .headline-notify {
      font-size: 1.2rem;
    }

    input[type="email"] {
      font-size: 1rem;
      padding: 0.7em 3rem 0.7rem 1rem;
      border-radius: 0.3rem;
      border: none;
      margin-right: 0.3rem;
    }
    
    #submit-btn {
      font-size: 1rem;
      padding: 0.7rem 2rem;
      border-radius: 0.3rem;
      border: none;
      background-color: #3a86ff;
      color: white;
      cursor: pointer;
    }
  }